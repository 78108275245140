import React, { useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { HiOutlineMailOpen } from "react-icons/hi";
import { LiaWhatsapp } from "react-icons/lia";
import { FaViber } from "react-icons/fa";
import { FaHandPointRight } from "react-icons/fa";
import { ComData } from "../../App";
import "./product.css";
import Axios from "axios";
import { Helmet } from 'react-helmet';
import * as Constans from "../../api/constants";
import { Fade } from "react-slideshow-image";
import he from 'he';
import { Img } from 'react-image';
import 'react-slideshow-image/dist/styles.css';
import LoadAni from '../../components/loadani';
// import SEO from "../../components/seo/SEO";
// import { WhatsAppShareButton } from 'react-share';

export let NewProduct = '';

const Product = () => {
  const { id } = useParams();
  const [product, setProducts] = useState([]);
  const [images, setImages] = useState([]);
  const [images2, setImages2] = useState([]);

  const config = {
    headers: { 'x-pos-netbees-token': `Basic ${process.env.REACT_APP_API_KEY}` }
  };

  useEffect(() => {
    Axios.get(Constans.ITEM_BY_ID + Constans.C_ID + '/' + id, config)
      .then((response) => {
        const result = response.data.result;
        setTimeout(() => {
          result.map(item => {
            setProducts(item);
            const Images = item.item_image;
            const urlsArray = Images.split(',');
            const newArr = urlsArray.filter(item => item !== '')
            setImages(newArr.slice(0, 3));
            setImages2(newArr.slice(0, 1));

          })
        }, 1000);
      })
      .catch((error) => {
        let collection = localStorage.getItem('ProductDetails');
        const result1 = [JSON.parse(collection)];
        setTimeout(() => {
          result1.map(item => {
            setProducts(item);
            const Images = item.item_image;
            const urlsArray = Images.split(',');
            const newArr = urlsArray.filter(item => item !== '')
            setImages(newArr.slice(0, 3));
          })
        }, 1000);
      });

  }, []);

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };



  if (!Object.keys(product).length > 0)
    return (
      <div className="NewLoadin" >
        <div className="w-full align-middle justify-center grid">
          <span className="relative align-middle justify-center flex h-20 w-20">
            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-700"></span>
            <span className="relative inline-flex rounded-full h-20 w-20 bg-red-500"></span>
          </span>
        </div>

      </div>
    );

  const link = `https://wa.me/+${ComData?.mobile}?text=${"https://ecommerce.netbees.com.sg/products/" + product?.item_slug + '/' + product.id}... Give Me Information about this Product`;

  const elink = `mailto:${ComData?.email}?subject=${product?.item_name}... Give Me Information about this Product`;

  const viberLink = `viber://chat?number=+${ComData?.mobile}`;

  const divStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 350,
    backgroundSize: "cover",
  }


  return (
    <Fragment>

      <Helmet>
        <meta charSet="utf-8" />
        <title>Bee's Shop | {product?.seo_title}</title>
        <meta property="title" content={`${product?.seo_title}`} />
        <meta name="description" content={product?.seo_description} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta property="og:title" content={product?.seo_title} />
        <meta property="og:description" content={product?.seo_description} />
        <meta property="og:image" content={Constans.AdminUrl + images2} />
        <meta property="og:image:alt" content="Product Image" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={"https://ecommerce.netbees.com.sg/" + product?.item_slug + '/' + product.id} />
        <meta name="twitter:card" content={Constans.AdminUrl + images2} />
        <meta name="twitter:title" content={product?.seo_title} />
        <meta name="twitter:description" content={product?.seo_description} />
        <meta name="twitter:image" content={Constans.AdminUrl + images2} />
      </Helmet>

      <section
        className="text-gray-600 body-font overflow-hidden bg-white -mt-60 "
        style={{ zIndex: 900, marginTop: -290 }}
      >

        <div className="container px-2 py-24 mx-auto">
          <div className="lg:w-5/5 mx-auto relative bg-white pt-20">
            <button
              onClick={goBack}
              className="flex text-lg pr-5 z-50 pl-4 ml-auto justify-center text-white bg-red-600 border-0 py-1 px-1 focus:outline-none hover:bg-red-800 absolute top-24 rounded"
            >
              <IoMdArrowRoundBack style={{ fontSize: 30 }} /> Back
            </button>

            <div className="slide-container">
              <Fade>
                {
                  images.map((item, index) => {
                    return (
                      <div index={index}>
                        <div style={{ ...divStyle }} >
                          <Img
                            alt={product?.item_name}
                            className=" lg:w-full w-full object-contain max-h-[350px] object-center "
                            src={Constans.AdminUrl + item}
                            style={{ marginTop: 0 }}
                            loader={<div><LoadAni /></div>}
                          />
                        </div>
                      </div>
                    )

                  })
                }
              </Fade>
            </div>

            <div className="lg:w-full w-full lg:pl-3 lg:py-6 mt-6 lg:mt-0">
              <h1 className="text-gray-900 text-2xl font-bold mb-1">
                {product?.item_name}
              </h1>
              <div className=" justify-between w-full  flex mt-1 mb-1">
                <div className=" pt-1 text-base w-[24%] align-middle text-red-600 text-center flex font-bold">
                  Order Now <div ><FaHandPointRight style={{ marginTop: 3, marginLeft: 15 }} /></div>
                </div>
                <a href={link} target="_blank" className="flex transition ease-in-out delay-150 h-[32px]  w-[24%]  py-1 text-center justify-center text-white text-[15px]  rounded bg-green-700 hover:-translate-y-1 hover:scale-105 hover:bg-green-900 duration-300 ...">
                  <LiaWhatsapp style={{ fontSize: 22, marginTop: 0 }} /> WhatsApp
                </a>
                <a href={elink} target="_blank" className="flex transition ease-in-out delay-150 h-[32px] text-white text-[15px] w-[24%]  py-1 text-center justify-center rounded bg-teal-500 hover:-translate-y-1 hover:scale-105 hover:bg-teal-900 duration-300 ...">
                  <HiOutlineMailOpen style={{ fontSize: 20, marginTop: 0 }} /> E-mail
                </a>
                <a href={viberLink} target="_blank" className="flex transition ease-in-out delay-150 h-[32px] text-white text-[15px] py-1 text-center justify-center w-[24%] rounded bg-violet-600 hover:-translate-y-1 hover:scale-105 hover:bg-violet-800 duration-300 ...">
                  <FaViber style={{ fontSize: 22, marginTop: 0 }} /> Viber
                </a>
              </div>
              <div className="border-b-2 border-gray-400 mt-6 -mb-5"></div>
              <div className=" mt-6 items-center pb-5  mb-5">
                <h4 className="text-gray-900 text-base font-bold">Description</h4>
                <div className="leading-relaxed text-sm"><div dangerouslySetInnerHTML={{ __html: he.decode(product?.description) }}></div></div>
              </div>
              <div className="border-dotted border-b-2 -mt-8 mb-5 border-gray-400 "></div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Product;
