import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "./styles.css";
import Axios from "axios";
import * as Constants from '../../api/constants';
import { Link } from "react-router-dom";
import Loader from '../loader';
import { Img } from 'react-image';
import LoadAni from '../loadani';
import {Token} from '../../App'

const Swipers = ({tokenOne}) => {
  const [category, setCategory] = useState([]);

  const config = {
    headers: { 'x-pos-netbees-token': `Basic ${process.env.REACT_APP_API_KEY}` }
};

  useEffect(() => {


    const config = {
      headers: { 'x-pos-netbees-token': `Basic ${process.env.REACT_APP_API_KEY}` }
    };
      Axios.get(Constants.CATEGORIES + Constants.C_ID,config)
      .then((response) => {
        const result = response.data.result;
        setCategory(result);
        localStorage.setItem('SwipperData', JSON.stringify(result));
      })
      .catch((error) => {
        let collection = localStorage.getItem('SwipperData');
        setCategory(JSON.parse(collection));
      });
  }, []);

  return (
    <>
      <style>
            {`
              @media (max-width: 520px) {
                .custom-sm {
                  bottom: 10px; /* Add margin for spacing */
                }
                .MainCon{
                  margin-top: -25px;
                  margin-bottom: -25px;
                }
                .custom-md {
                  top: 110px; /* Add margin for spacing */
                }
                .MainContent{
                  margin-top: -25px; 
                  
                }
              }

              @media (min-width: 351px) and (max-width: 780px) {
                .custom-md {
                  
                  bottom: -40px; /* Add margin for spacing */
                }
                .MainCon{
                  margin-top: -25px;
                  
                }
              }

              @media (min-width: 781px) {
                .custom-lg {
                   bottom: -40px; /* Add margin for spacing */
                }
                .MainCon{
                  margin-top: -25px;
                }
              }
            `}
          </style>
      {category && category.length > 0 ? (
        
        <div className="MainCon" style={{ width: '100%', }}>
         
          <Swiper
            slidesPerView={6}
            spaceBetween={10}
            freeMode={true}
            pagination={{
              clickable: true,
            }}

            autoplay={{
              delay: 2500,

            }}
            modules={[Autoplay]}
            className="mySwiper"
            style={{ width: '100%', height: 150 ,paddingRight: 50}}
          >
            {category.map((item) => {
              let newImage = '';
              if (item.image == null) {
                newImage = '';
              } else {
                  let match = /\.(\w+)$/.exec(item.image);
                  let type = match ? `.${match[1]}` : ``;
                  let name = item.image.substring(item.image.lastIndexOf('.') + 0, item.image) + '_thumb';
                  newImage = name + type;
              }
              return (
                <SwiperSlide className="MainContent" style={{ width: '20%', margin: '1%',}}>
                  <Link className="cursor-pointer text-center drop-shadow-xl" to={`/category/${item.category_name}/${item.id}`}>
                    <Img
                      className="h-auto max-w-full imaged rounded-full"
                      src={Constants.AdminUrl + newImage}
                      style={{ marginTop: 20 }}
                      loader={<div><LoadAni /></div>}
                    />
                  </Link>
                  <p
                    style={{
                      position: "absolute",
                      fontSize: 11,
                      fontWeight: "bold",
                      width: '100%',
                      textAlign: 'center',
                    }}
                    className="custom-sm custom-md custom-lg"
                  >

                    {item.category_name}
                  </p>
                </SwiperSlide>

              );
            })}
          </Swiper>
        </div>


      ) : (
        <Loader />
      )}
    </>
  );
};

export default Swipers;
