import React, { useState, useEffect } from "react";
import Search from "../search/index.js";
import { Link } from "react-router-dom";
import * as Constants from "../../api/constants.js";
import Axios from "axios";
import {Token} from '../../App.js'

const Header = ({ toggleSidebar,tokenOne }) => {

  const [clogo, setClogo] = useState(false);
  const [nlogo, setNlogo] = useState(true);
  const [data, setData] = useState();

  useEffect(() => {
      const config = {
        headers: { 'x-pos-netbees-token': `Basic ${process.env.REACT_APP_API_KEY}` }
      };
      Axios.get(Constants.COMPANYDATA + Constants.C_ID,config)
      .then((response) => {
        const result = response.data.result;
        result.map(items => {
          setData(items);
          setClogo(true)
          setNlogo(false)
        })
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    let collection = localStorage.getItem('NewToken');
    if(collection === null){

    }else {
      
    }
  }, []);


  return (
    <header
      style={{ backgroundColor: "#EA1920" }}
      className="text-gray-600 body-font shadow-lg"
    >
      <button
        class="mt-10 inline-block rounded bg-primary px-[2%]  text-xs font-medium uppercase leading-tight text-white  transition duration-150 ease-in-out "
        data-te-sidenav-toggle-ref
        data-te-target="#sidenav-3"
        aria-controls="#sidenav-3"
        aria-haspopup="true"
        style={{position: 'relative', top: -22, left: 25,  zIndex: 20,}}
        onClick={toggleSidebar}>
        <span class="block [&>svg]:h-5 [&>svg]:w-5 [&>svg]:text-white">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            class="h-5 w-5">
            <path
              fill-rule="evenodd"
              d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
              clip-rule="evenodd" />
          </svg>
        </span>
      </button>
      <div className="container mx-auto flex flex-wrap px-6 pb-3 pt-1 flex-col md:flex-row items-center" style={{marginTop: -55}}>
        <Link
          to={"/"}
          className=" title-font font-medium items-center w-full text-gray-900 md:mb-0 grid justify-items-center"
          style={{ marginBottom: 10 }}
        >
          {
            clogo &&
            <img
              className="cursor-pointer"
              src={Constants.AdminUrl + data.company_logo}
              alt="logo"
              width={250}
            />
          }
          {
            nlogo &&
            <div className="flex text-white underline h-11 decoration-black hover:decoration-white decoration-double font-extralight text-4xl hover:text-black cursor-pointer text-center transition">

            </div>
          }

          {/* <div className="flex text-white underline decoration-black mb-2 decoration-double font-extralight text-6xl hover:text-black cursor-pointer text-center transition">
          <img
            className=" cursor-pointer"
            src="https://cdn.dribbble.com/users/1199152/screenshots/18003412/media/d47b8bd9173b4c7b8577585002d29b61.gif"
            alt="logo"
            style={{borderRadius: 10  ,  width:65, height: 65,marginTop: 2, marginRight: 10}}
          />  B-SHOP 
          </div> */}
        </Link>
        <div style={{ width: "100%", }} className=" px-[10px] pr-[11px">
          <Search />
        </div>
        <nav
          style={{ width: "100%", zIndex: 10 }}
          className="flex  flex-wrap items-center text-base md:ml-auto"
        ></nav>

      </div>
    </header>
  );
};

export default Header;
