import React, { useEffect, useState } from "react";
import Products from "../../components/products";
import Swiper from "../../components/swiper";
// import Filters from "../../components/filters";
// import MobileNav from "../../components/mobilenav";
import Axios from 'axios';
import * as Constants from '../../api/constants';
import Loader from '../../components/loader';
import { Helmet } from 'react-helmet';
// import OgImage from '../../assets/logo192.png';
// import UIImage from '../../assets/logo512.png';

const Home = ({ tokenOne }) => {
  const [products, setProducts] = useState([]);

  const config1 = {
    headers: { 'x-pos-netbees-token': `Basic ${process.env.REACT_APP_API_KEY}` }
  };

  useEffect(() => {
    Axios.get(Constants.ALL_PRODUCTS + Constants.C_ID + "/0/0/0", config1)
      .then((response) => {
        const result = response.data.result;
        localStorage.setItem('ProductsAll', JSON.stringify(result));
        setProducts(result);
      })
      .catch((error) => {
        let collection = localStorage.getItem('ProductsAll');
        setProducts(JSON.parse(collection));
      });
    // const pageTitle = 'Bee\'s Shop | Catalogue App';
    // const pageDescription = 'Supper fast Bee\'s Shop catalog app. Browse a wide range of products, from fashion to electronics, and enjoy a seamless shopping experience.';

    // document.title = pageTitle;
    // const metaDescription = document.querySelector('meta[name="description"]');
    // if (metaDescription) {
    //   metaDescription.setAttribute('content', pageDescription);
    // }

    // const ogTitle = 'Ultimate Bee\'s Shop Catalog App';
    // const ogDescription = 'Discover and shop the latest trends with our intuitive Bee\'s Shop catalog app. Browse a wide range of products, from fashion to electronics, and enjoy a seamless shopping experience.';
    // const ogImage = OgImage;
    // const uiImage = UIImage;

    // document.querySelector('meta[property="og:title"]').setAttribute('content', ogTitle);
    // document.querySelector('meta[name="description"]').setAttribute('content', pageDescription);
    // document.querySelector('meta[property="og:description"]').setAttribute('content', ogDescription);
    // document.querySelector('meta[property="og:image"]').setAttribute('content', ogImage);
    // document.querySelector('meta[property="og:image:alt"]').setAttribute('content', 'App Logo');
    // document.querySelector('meta[property="og:type"]').setAttribute('content', 'website');
    // document.querySelector('meta[property="og:url"]').setAttribute('content', 'https://ecommerce.netbees.com.sg/');
    // document.querySelector('meta[name="twitter:card"]').setAttribute('content', uiImage);
    // document.querySelector('meta[name="twitter:title"]').setAttribute('content', ogTitle);
    // document.querySelector('meta[name="twitter:description"]').setAttribute('content', ogDescription);
    // document.querySelector('meta[name="twitter:image"]').setAttribute('content', uiImage);
  }, []);


  return (
    <>
      <Helmet>
        <title>Bee's Shop | Catalogue App</title>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
        <meta name="description" content="Supper fast Bee's Shop catalog app. Browse a wide range of products, from fashion to electronics, and enjoy a seamless shopping experience." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta property="og:title" content="Ultimate Bee's Shop Catalog App" />
        <meta property="og:description" content="Discover and shop the latest trends with our intuitive Bee's Shop catalog app. Browse a wide range of products, from fashion to electronics, and enjoy a seamless shopping experience." />
        <meta property="og:image" content="https://catelogue.netbees.com.sg/uploads/2/logo/1692720418.png" />
        <meta property="og:image:alt" content="App Logo" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://ecommerce.netbees.com.sg/" />
        <meta name="twitter:card" content="https://catelogue.netbees.com.sg/uploads/2/logo/1692720418.png" />
        <meta name="twitter:title" content="Ultimate Bee's Shop Catalog App" />
        <meta name="twitter:description" content="Discover and shop the latest trends with our intuitive Bee's Shop catalog app. Browse a wide range of products, from fashion to electronics, and enjoy a seamless shopping experience." />
        <meta name="twitter:image" content="https://catelogue.netbees.com.sg/uploads/2/logo/1692720418.png" />
        
      </Helmet>
      <Swiper tokenOne={tokenOne} />

      {products && products.length > 0 ? (
        <>
          <Products products={products} tokenOne={tokenOne} /></>

      ) : (
        <Loader />
      )}

    </>
  );
};

export default Home;
