import React, { useEffect, useState } from "react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import MobileNav from "./components/mobilenav";
import RightSide from "./modules/rightside";
import { HelmetProvider } from 'react-helmet-async';
import { hydrate, render } from "react-dom";



const APP = (<BrowserRouter>
  <React.StrictMode>
    <HelmetProvider>
      <div className="Main_div">
        <nav className="sidebar">
          <div className="scrollbox">
            <div className="scrollbox-inner">
              <App />
            </div>
          </div>
          <footer style={{ backgroundColor: "transparent" }}>
            <MobileNav />
          </footer>
        </nav>

        <div className="content">
          <div className="content-wrapper">
            <RightSide />

          </div>
        </div>
      </div>
    </HelmetProvider>


  </React.StrictMode>
</BrowserRouter>);

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(APP, rootElement);
} else {
  render(APP, rootElement);
}

// src/index.js

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js').then((registration) => {
      console.log('Service Worker registered with scope:', registration.scope);
    }).catch((error) => {
      console.error('Service Worker registration failed:', error);
    });
  });
}


reportWebVitals();
