import React from 'react'
import { useNavigate } from "react-router";
import * as Constans from "../../api/constants";

const SearchItems = ({ product, isSearch, setSearch,newImage }) => {

    const navigate = useNavigate();
    return (
        <div
            className={`m-auto flex flex-col gap-2 h-4/5 pt-[4px] pb-[4px] rounded-sm shadow-sm bg-white/[0.6] mb-2 mx-6 ${isSearch ? "cursor-pointer hover:bg-black/5" : ""
                }`}
            onClick={() => {
                if (isSearch) {
                    setSearch("");
                    navigate(`products/${product.item_name}/${product.id}`);
                }
            }}
            style={{ zIndex: 9999 }}
        >
            <div className="flex  flex-wrap w-full">
                <div className="flex flex-wrap xs:flex-nowrap  flex-1  gap-5">
                    <div
                        className={` bg-black/[0.075] ${isSearch ? "h-10 w-10 " : "h-12 w-12"
                            } rounded-full flex `}
                    >
                        <img src={Constans.AdminUrl+newImage} alt="" className="object-fit w-full " style={{borderRadius: 500}} />
                    </div>
                    <div className="flex justify-center flex-col ">
                        <h2 className=" text-base font-semibold">{product.item_name}</h2>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SearchItems;
